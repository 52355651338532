import contactImage from "../assets/images/contact-background.svg";
import chevronImage from "../assets/images/chevron.svg";
import infoImage from "../assets/images/info.svg";
import womenGardeningImage from "../assets/images/women-gardening.svg";

// Import Slider Images
import sliderImg1 from "../assets/slider/gil-ribeiro-unsplash.jpg";
import sliderImg2 from "../assets/slider/verne-ho-unsplash.jpg";
import sliderImg3 from "../assets/slider/oliver-cole-unsplash.jpg";
import sliderImg4 from "../assets/slider/anders-jilden-unsplash.jpg";
import sliderImg5 from "../assets/slider/jonathan-simcoe-unsplash.jpg";
import sliderImg6 from "../assets/slider/anatolii-nesterov-unsplash.jpg";
import sliderImg7 from "../assets/slider/priscilla-du-preez-unsplash.jpg";
import sliderImg8 from "../assets/slider/michael-fortsch-unsplash.jpg";

// Main Page SCSS cleanundfrisch.scss
require("../styles/cleanundfrisch.scss");

// Navigation Styles
require("../styles/navigation.scss");

console.log("cleanundfrisch script loaded");

const d = document;

// Slider Images in array
const sliderImages = [
  sliderImg1,
  sliderImg2,
  sliderImg3,
  sliderImg4,
  sliderImg5,
  sliderImg6,
  sliderImg7,
  sliderImg8,
];

// Select node for Slider Images
// const sliderContainer = d.querySelector(".slider-container");
const slides = d.querySelector(".slides");

// Crate Img
function sliderImgMarkup(imgSrc) {
  const img = d.createElement("img");
  img.src = imgSrc;
  img.alt = "Cover Image";
  return img;
}

// Render each created img
sliderImages.forEach((img, index) => {
  const child = sliderImgMarkup(img);

  if (index == sliderImages.length - 1) return;

  slides.appendChild(child);
});

// duplicate first item
const createCloneOfFirstImage = () => {
  const child = sliderImgMarkup(sliderImg1);
  slides.appendChild(child);
};
createCloneOfFirstImage();

const slideHero = () => {
  let intervallCounter = 1;
  const lastImage = sliderImages.length;

  // intervall
  const sliderInterval = setInterval(() => {
    let slideValue = `-${100 * intervallCounter}%`;

    if (intervallCounter == lastImage) {
      slides.style.transition = "none";
      slides.style.transform = `translateX(0)`;
      intervallCounter = 2;
      clearInterval(sliderInterval);
      slideHero();
      return;
    }
    slides.style.transition = "transform 1.5s ease";
    slides.style.transform = `translateX(${slideValue})`;
    intervallCounter += 1;
  }, 5000);
};
slideHero();

// Select checkbox and set to checked on inital load to display menu
const menu = d.querySelector("#menu");
menu.checked = true;

// Inject Background Image into Contact Section
const contactImageContainer = d.querySelector(".contact-image");
contactImageContainer.src = contactImage;

// Inject Chevron into every chevron image
const chevronNodeList = d.getElementsByClassName("chevron");
Array.from(chevronNodeList).map((chevron) => (chevron.src = chevronImage));

// Inject Info icon for email hover information
const emailInfoImage = d.querySelector(".email-info");
emailInfoImage.src = infoImage;

// Copy email adress to clipboard
const mailToLink = d.querySelector(".email-address");
mailToLink.addEventListener("click", () => {
  navigator.clipboard
    .writeText("cleanundfrisch@gmail.com")
    .then((success, error) => {
      if (error) {
        reject(
          alert("Konnte nicht kopiert werden, manuelle Eingabe erforderlich.")
        );
      }
    });
});

// Select all labels which toggle accordions in service-details (node list)
const selectAccordionLabels = d.getElementsByClassName("service-details-label");
console.log("selectAccordionLabels", selectAccordionLabels);

// Select all paper boxes
const paperBoxNodeList = d.getElementsByClassName("service-details-paper-box");
console.log("paperBoxes", paperBoxNodeList);

Array.from(selectAccordionLabels).forEach((label, i) => {
  label.addEventListener("change", () => {
    console.log("label:", selectAccordionLabels[i]);
    let paperBox = paperBoxNodeList[i];
    let currentChevron = chevronNodeList[i];

    paperBox.classList.toggle("paper-box-open");
    currentChevron.classList.toggle("chevron-active");
  });
});

// Inject Footer Background Image
const footerImage = d.querySelector(".footer-bg-image");
footerImage.src = womenGardeningImage;
